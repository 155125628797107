// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-index-js": () => import("./../../../src/pages/about/index.js" /* webpackChunkName: "component---src-pages-about-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-policy-index-js": () => import("./../../../src/pages/policy/index.js" /* webpackChunkName: "component---src-pages-policy-index-js" */),
  "component---src-pages-portfolio-index-js": () => import("./../../../src/pages/portfolio/index.js" /* webpackChunkName: "component---src-pages-portfolio-index-js" */),
  "component---src-pages-services-design-index-js": () => import("./../../../src/pages/services/design/index.js" /* webpackChunkName: "component---src-pages-services-design-index-js" */),
  "component---src-pages-services-index-js": () => import("./../../../src/pages/services/index.js" /* webpackChunkName: "component---src-pages-services-index-js" */),
  "component---src-pages-services-marketing-index-js": () => import("./../../../src/pages/services/marketing/index.js" /* webpackChunkName: "component---src-pages-services-marketing-index-js" */),
  "component---src-pages-services-web-dev-corp-site-index-js": () => import("./../../../src/pages/services/web-dev/corp-site/index.js" /* webpackChunkName: "component---src-pages-services-web-dev-corp-site-index-js" */),
  "component---src-pages-services-web-dev-index-js": () => import("./../../../src/pages/services/web-dev/index.js" /* webpackChunkName: "component---src-pages-services-web-dev-index-js" */),
  "component---src-pages-services-web-dev-internet-shop-index-js": () => import("./../../../src/pages/services/web-dev/internet-shop/index.js" /* webpackChunkName: "component---src-pages-services-web-dev-internet-shop-index-js" */),
  "component---src-pages-services-web-dev-landing-index-js": () => import("./../../../src/pages/services/web-dev/landing/index.js" /* webpackChunkName: "component---src-pages-services-web-dev-landing-index-js" */),
  "component---src-pages-services-web-dev-quiz-site-index-js": () => import("./../../../src/pages/services/web-dev/quiz-site/index.js" /* webpackChunkName: "component---src-pages-services-web-dev-quiz-site-index-js" */),
  "component---src-pages-services-web-dev-small-site-index-js": () => import("./../../../src/pages/services/web-dev/small-site/index.js" /* webpackChunkName: "component---src-pages-services-web-dev-small-site-index-js" */),
  "component---src-templates-project-js": () => import("./../../../src/templates/project.js" /* webpackChunkName: "component---src-templates-project-js" */)
}

